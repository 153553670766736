import Image from 'next/image'
import { ExternalLink } from './ExternalLink'
import { DOCS_DOMAIN } from '../../constants'

type FooterLink = {
  title: string
  href: string
  className: string
}

const footerLinks: FooterLink[] = [
  {
    title: 'Documentation',
    href: DOCS_DOMAIN,
    className: 'lg:order-1'
  },
  {
    title: 'Careers',
    href: 'https://offchainlabs.com/careers',
    className: 'lg:order-2'
  },
  {
    title: 'Blog',
    href: 'https://medium.com/offchainlabs',
    className: 'lg:order-4'
  },
  {
    title: 'Recent Press',
    href: 'https://offchainlabs.com/#press',
    className: 'lg:order-5'
  },
  {
    title: 'ToS',
    href: 'https://arbitrum.io/tos',
    className: 'lg:order-3'
  },
  {
    title: 'Privacy Policy',
    href: 'https://www.iubenda.com/privacy-policy/76750372',
    className: 'lg:order-6'
  }
]

export function Footer() {
  return (
    <footer className="z-[1] flex justify-center">
      <div className="flex w-full max-w-[1440px] flex-col space-y-8 py-20 text-white lg:px-8 lg:py-8">
       

        <div className="flex flex-col space-y-8">

        </div>
      </div>
    </footer>
  )
}
